import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAxios from 'common/axios';
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const fetchProductsOfAccount = createAsyncThunk(
  'productsOfAccount/fetchProductsOfAccount',
  async ({ showId, accountId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/${showId}/${accountId}/products`, {
        withCredentials: true
      });
      return {
        attendeeProducts: data.confAttendees,
        sponsorshipProducts: data.sponsorships
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Fetch Booths for Account
export const fetchBoothsForAccount = createAsyncThunk(
  'productsOfAccount/fetchBoothsForAccount',
  async ({ showId, accountId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/${showId}/${accountId}/booths`, {
        withCredentials: true,
      });
      return response.data.booths;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message);
    }
  }
);

const productsOfAccountSlice = createSlice({
  name: 'productsOfAccount',
  initialState: {
    attendeeProducts: [],
    sponsorshipProducts: [],
    boothProducts: [],
    boothProductsStatus: 'idle',
    productsOfAccountStatus: 'idle',
    error: null
  },
  reducers: {
    // Synchronous reducer to update only provided state properties
    // this is use to update the products of an Account with the new products added with the old ones
    updateProductsOfAccountState: (state, action) => {
      const { attendeeProducts, sponsorshipProducts, boothProducts } = action.payload;
      
      if (attendeeProducts !== undefined) {
        // Merge old and new attendeeProducts
        state.attendeeProducts = [...state.attendeeProducts, ...attendeeProducts];
      }
      if (sponsorshipProducts !== undefined) {
        // Merge old and new sponsorshipProducts
        state.sponsorshipProducts = [...state.sponsorshipProducts, ...sponsorshipProducts];
      }
      if (boothProducts !== undefined) {
        // Merge old and new boothProducts
        state.boothProducts = [...state.boothProducts, ...boothProducts];
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Products of Account
      .addCase(fetchProductsOfAccount.pending, (state) => {
        state.productsOfAccountStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchProductsOfAccount.fulfilled, (state, action) => {
        state.productsOfAccountStatus = 'succeeded';
        state.attendeeProducts = action.payload.attendeeProducts;
        state.sponsorshipProducts = action.payload.sponsorshipProducts;
        state.booths = action.payload.booths;
      })
      .addCase(fetchProductsOfAccount.rejected, (state, action) => {
        state.productsOfAccountStatus = 'failed';
        state.error = action.payload;
      })
      // Booths of Account
      .addCase(fetchBoothsForAccount.pending, (state) => {
        state.boothProductsStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchBoothsForAccount.fulfilled, (state, action) => {
        state.boothProductsStatus = 'succeeded';
        state.boothProducts = action.payload;
      })
      .addCase(fetchBoothsForAccount.rejected, (state, action) => {
        state.boothProductsStatus = 'failed';
        state.error = action.payload;
      });
  }
});
// Export the action for dispatching
export const { updateProductsOfAccountState } = productsOfAccountSlice.actions;  
export default productsOfAccountSlice.reducer;